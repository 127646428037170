import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
import { getDeltaBucketsAsync } from "../../../../Redux/chartSlice";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CircleIcon from "@mui/icons-material/Circle";
import AddIcon from "@mui/icons-material/Add";
import { Tooltip } from "@mui/material";

const headCells = [
  {
    id: "lower-limit",
    numeric: true,
    disablePadding: false,
    label: "Lower Limit",
  },
  {
    id: "upper_limit",
    numeric: true,
    disablePadding: false,
    label: "Upper Limit",
  },
  {
    id: "keyword",
    numeric: true,
    disablePadding: false,
    label: "Keyword",
  },
  {
    id: "color",
    numeric: true,
    disablePadding: false,
    label: "Color",
  },
  {
    id: "change",
    numeric: true,
    disablePadding: false,
    label: "Change",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { toggleFormOpen, setFormValues, initialValues } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 4 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Manage Delta Bins
      </Typography>
      <Tooltip title="Add">
        <IconButton
          onClick={async () => {
            await setFormValues(initialValues);
            toggleFormOpen();
          }}
        >
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default function DeltaTable(props) {
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    toggleFormOpen,
    setFormValues,
    initialValues,
    confirmDeleteOpen,
    setConfirmDeleteID,
  } = props;

  const dispatch = useDispatch();

  const buckets = useSelector((state) => state.chartBuckets.deltaBuckets);

  let rows = buckets;

  useEffect(() => {
    dispatch(getDeltaBucketsAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = (id) => {
    setConfirmDeleteID(id);
    confirmDeleteOpen();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page >= 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper sx={{ width: "90%", height: "90%", my: 5 }} elevation={5}>
        <EnhancedTableToolbar
          setPage={setPage}
          toggleFormOpen={toggleFormOpen}
          setFormValues={setFormValues}
          initialValues={initialValues}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 500, height: 300 }}
            aria-labelledby="tableTitle"
            size="small"
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell align="center">{row.lowerLimit}</TableCell>
                      <TableCell align="center">
                        {row.upperLimit ? row.upperLimit : "+"}
                      </TableCell>
                      <TableCell align="center">{row.keyword}</TableCell>
                      <TableCell align="center">
                        <CircleIcon sx={{ color: row.color }} />
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={async () => {
                                await setFormValues(row);
                                toggleFormOpen(row);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                handleDelete(row.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
