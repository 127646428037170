import { Navigate } from "react-router";
import { useSelector ,useDispatch} from "react-redux";
import { getAuthToken } from "../Helpers/deps";
import {setLogIn} from "../Redux/authSlice";

export default function RequireAuth({ children }) {
  const dispatch=useDispatch();
  
  const token=getAuthToken();
  if(token){
   dispatch(setLogIn());
  }
  const authed=useSelector(state=>state.auth.isLoggedIn);
  const isAdmin=useSelector(state=>state.auth.isAdmin);
     

    return (isAdmin&&authed)
      ? children
      : <Navigate to="/login" replace />;
  }

