import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import Autocomplete from "@mui/material/Autocomplete";
import { useDispatch, useSelector } from "react-redux";
import { getDevicesAsync } from "../../../Redux/devicesSlice";
import { Button, TextField } from "@mui/material";

const headCells = [
  {
    id: "device_name",
    numeric: true,
    disablePadding: true,
    label: "Device Name",
  },
  {
    id: "device_host",
    numeric: false,
    disablePadding: false,
    label: "Device Host",
  },
  {
    id: "device_driver",
    numeric: false,
    disablePadding: false,
    label: "Driver",
  },
  {
    id: "region",
    numeric: true,
    disablePadding: false,
    label: "Region",
  },
  {
    id: "change",
    numeric: true,
    disablePadding: true,
    label: "Change",
  },
];

function EnhancedTableHead() {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const {
    addForm,
    search,
    setSearch,
    setPage,
    region,
    setRegion,
    inputValue,
    setInputValue,
    options,
  } = props;

  const handleRegionChange = (evt, newValue) => {
    setRegion(newValue);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 4 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Devices
      </Typography>

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ mt: 1, mx: 2, width: 300, maxWidth: "200px" }}>
          <Autocomplete
            value={region}
            onChange={handleRegionChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                name="region"
                label="Region"
              />
            )}
            disableClearable
          />
        </Box>
        <TextField
          id="search-regions"
          label="Search"
          variant="standard"
          placeholder="Enter Device Name"
          value={search}
          onChange={(e) => {
            setPage(0);
            setSearch(e.target.value);
          }}
        />
        <Tooltip title="Add Device">
          <IconButton onClick={addForm}>
            <AddIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Toolbar>
  );
};

export default function DevicesTable(props) {
  const initialLoad = useRef(true);
  const [page, setPage] = useState(0);

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [inputValue, setInputValue] = useState("All");

  const [filteredRegion, setFilteredRegion] = useState("All");

  const regions = useSelector((state) => state.regions.regions);

  const options = ["All", ...regions.map((region) => region.dma_name)];
  const dispatch = useDispatch();
  const {
    addDeviceFormOpen,
    editDeviceFormOpen,
    mapDeviceFormOpen,
    confirmDeleteOpen,
    setConfirmDeleteID,
  } = props;
  const devices = useSelector((state) => state.devices.devices);
  const [rows, setRows] = useState(devices);

  const [search, setSearch] = useState("");

  useEffect(() => {
    if (initialLoad.current) {
      dispatch(getDevicesAsync())
        .unwrap()
        .then((payload) => {
          setRows(payload);
        });
      initialLoad.current = false;
    } else {
      if (search === "") {
        if (filteredRegion === "All") {
          setRows(devices);
        } else {
          const filteredDevices = devices.filter((device) => {
            if (device.regions.length > 0)
              return filteredRegion === device.regions[0].dma_name;
            return false;
          });
          setRows(filteredDevices);
        }
      } else {
        const filteredDevices = devices.filter((device) => {
          return device.name.includes(search);
        });
        setRows(filteredDevices);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredRegion, search, devices]);

  const handleDelete = (id) => {
    setConfirmDeleteID(id);
    confirmDeleteOpen();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditRegionFormOpen = (id) => {
    editDeviceFormOpen(id);
  };

  const handleMapDevice = (row) => {
    mapDeviceFormOpen(row);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper sx={{ width: "90%", height: "90%", mb: 2 }} elevation={5}>
        <EnhancedTableToolbar
          addForm={addDeviceFormOpen}
          search={search}
          setSearch={setSearch}
          setPage={setPage}
          region={filteredRegion}
          setRegion={setFilteredRegion}
          inputValue={inputValue}
          setInputValue={setInputValue}
          regions={regions}
          options={options}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  return (
                    <TableRow hover tabIndex={-1} key={row.name}>
                      <TableCell
                        component="th"
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.host}</TableCell>
                      <TableCell align="left">{row.driverName}</TableCell>
                      <TableCell align="center">
                        {row.regions && row.regions.length > 0 ? (
                          row.regions[0].dma_name
                        ) : (
                          <Button
                            onClick={() => handleMapDevice(row)}
                            fullWidth
                          >
                            Click to add Region
                          </Button>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEditRegionFormOpen(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                handleDelete(row.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
