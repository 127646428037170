import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  Autocomplete,
  OutlinedInput,
  Checkbox,
  ListItemText,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getRegionDevicesAsync,
  selectDevices,
  selectRegion,
  // toggleOnlyLastCoordinate,
  // toggleUncorrected,
  getAllDevicesAsync,
} from "../../Redux/dataSlice";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

const StyledBox = styled(Box, { name: "StyledBox", slot: "Wrapper" })({
  gridArea: "sidebar",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  width: 200,
});

export default function Sidebar() {
  const regions = useSelector((state) => state.data.regions);

  const options = [
    "All",
    ...regions.map((region) => region.dma_rank + " " + region.dma_name),
  ];

  const devices = useSelector((state) =>
    state.data.devices.map((device) => device.name)
  );

  const deviceNames = ["All", ...devices];

  const [inputValue, setInputValue] = useState("Select Region");
  const [value, setValue] = useState(options[0]);
  const [prevSelectedAll, setPrevSelectedAll] = useState(true);

  const selectedDevices = useSelector((state) => state.data.selectedDevices);

  const dispatch = useDispatch();
  // const showUncorrected = useSelector((state) => state.data.uncorrected);

  // const showOnlyLastCoordinate = useSelector(
  //   (state) => state.data.onlyLastCoordinate
  // );

  // const handleUncorrected = (event) => {
  //   const uncorrected = event.target.checked;
  //   dispatch(toggleUncorrected({ uncorrected }));
  // };

  // const handleOnlyLastCoordinate = (event) => {
  //   const onlyLastCoordinate = event.target.checked;
  //   dispatch(toggleOnlyLastCoordinate({ onlyLastCoordinate }));
  // };

  useEffect(() => {
    dispatch(selectDevices({ selectedDevices: ["All", ...devices] }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRegionChange = async (event, newValue) => {
    if (!newValue) {
      setValue("");
      setValue(newValue);
      return;
    }

    setValue(newValue);
    if (newValue === "All") {
      await dispatch(getAllDevicesAsync());
    } else {
      const id = regions.filter(
        (region) => region.dma_rank === parseInt(newValue.split(" ")[0])
      )[0].id;
      await dispatch(selectRegion({ region: id }));

      await dispatch(
        getRegionDevicesAsync({
          id: id,
        })
      )
        .unwrap()
        .then(async (payload) => {
          await dispatch(
            selectDevices({
              selectedDevices: ["All", ...payload.map((device) => device.name)],
            })
          );
        });
    }
  };

  const handleDeviceSelect = async (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("All")) {
      setPrevSelectedAll(true);
      await dispatch(selectDevices({ selectedDevices: ["All", ...devices] }));
    } else {
      if (prevSelectedAll) {
        await dispatch(selectDevices({ selectedDevices: [] }));
        setPrevSelectedAll(false);
      } else {
        const selected = typeof value === "string" ? value.split(",") : value;
        await dispatch(selectDevices({ selectedDevices: selected }));
      }
    }
  };

  return (
    <StyledBox>
      <Box sx={{ my: 1, p: 2 }}>
        <Typography>Filter Region</Typography>
        <Box sx={{ my: 1, maxWidth: "200px" }}>
          <Autocomplete
            value={value}
            onChange={handleRegionChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Region" />
            )}
          />
        </Box>
      </Box>

      <Box sx={{ my: 1, p: 2 }}>
        <Typography>Filter Devices</Typography>
        <Box
          sx={{
            my: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl
            sx={{
              m: 1,
              margin: 1,
              minWidth: "calc(100%)",
              maxWidth: 100,
            }}
          >
            <InputLabel id="demo-multiple-checkbox-label" size="small">
              Device
            </InputLabel>
            <Select
              multiple
              value={selectedDevices}
              onChange={handleDeviceSelect}
              input={<OutlinedInput label="Device" size="small" />}
              renderValue={(selected) => {
                if (!selected.includes("All")) return selected.join(", ");
                else return "All";
              }}
              MenuProps={MenuProps}
            >
              {deviceNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={selectedDevices.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Box
        sx={{
          my: 1,
          p: 2,
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showUncorrected}
                onChange={handleUncorrected}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<Typography variant="body2">Show Uncorrected</Typography>}
          />
        </Box> */}
        {/* <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "space-between",
          }}
        >
          <FormControlLabel
            control={
              <Switch
                checked={showOnlyLastCoordinate}
                onChange={handleOnlyLastCoordinate}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label={<Typography variant="body2">Last Coordinate</Typography>}
          />
        </Box> */}
      </Box>
    </StyledBox>
  );
}
