import React from "react";
import { Button, Dialog, DialogTitle, DialogActions } from "@mui/material";
import { useDispatch } from "react-redux";
import { deleteRegionAsync } from "../../../Redux/regionsSlice";

export default function ConfirmDialog(props) {
  const { open, handleClose, id, setSnackbarProps } = props;
  const dispatch = useDispatch();

  const handleConfirm = async () => {
    await dispatch(deleteRegionAsync({ id }))
      .unwrap()
      .then(() => {
        setSnackbarProps({
          open: true,
          severity: "success",
          msg: "Deleted region successfully",
        });
        handleClose();
      })
      .catch(() => {
        setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Failed to delete region, Try again!",
        });
      });
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Do you confirm deleting the Region?"}
        </DialogTitle>

        <DialogActions>
          <Button onClick={handleConfirm}>Confirm</Button>
          <Button onClick={handleClose} autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
