import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Checkbox,
  ListItemText,
  OutlinedInput,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Popover,
  Typography,
  Divider,
} from '@mui/material';

import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import { useSelector } from 'react-redux';
import BarChart from './BarChart';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Chart(props) {
  const {
    title,
    buckets,
    type,
    color,
    needRecursion,
    setNeedRecursion,
    recursiveData,
    isInitialLoading,
  } = props;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
        width: '100px',
      },
    },
  };

  const Buckets = buckets;

  const devices = useSelector((state) =>
    state.data.devices.map((device) => device.name)
  );

  const deviceNames = ['All', ...devices];
  const recentOptions = ['--SELECT--', 'Last 1 hour', 'Last 2 hours'];
  const token = useSelector((state) => state.auth.token);

  const Data = [];

  Buckets.forEach((bucket, idx) => {
    if (bucket.upperLimit !== null) {
      Data.push({
        range: `${
          type === 'signal'
            ? bucket.lowerLimit.split('.')[0]
            : bucket.lowerLimit
        }-${
          type === 'signal'
            ? bucket.upperLimit.split('.')[0]
            : bucket.upperLimit
        }`,
        color: bucket.color,
        count: 0,
        percentage: 0,
      });
    } else {
      Data.push({
        range: `${
          type === 'signal'
            ? bucket.lowerLimit.split('.')[0]
            : bucket.lowerLimit
        }+`,
        color: bucket.color,
        count: 0,
        percentage: 0,
      });
    }
  });

  const initialLoad = useRef(true);

  const [prevSelectedAll, setPrevSelectedAll] = useState(true);
  const [selectedDevices, setSelectedDevices] = useState(['All', ...devices]);

  const [currentTime, setCurrentTime] = useState(new Date());
  const [isLoading, setIsLoading] = useState(true);

  const [isError, setIsError] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(null);

  const popoverOpen = Boolean(isPopoverOpen);

  const [selectedDropDownValue, setSelectedDropDownValue] = useState(
    recentOptions[1]
  );
  const [fromTime, setFromTime] = useState(
    new Date(new Date() - 60 * 60 * 1000)
  );
  const [toTime, setToTime] = useState(currentTime);
  const maxTime = useRef(currentTime);

  const handlePopover = (event) => {
    setIsPopoverOpen(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setIsPopoverOpen(null);
  };

  const handleDeviceSelect = async (event) => {
    const {
      target: { value },
    } = event;

    if (value.includes('All')) {
      setPrevSelectedAll(true);
      setSelectedDevices(['All', ...devices]);
    } else {
      if (prevSelectedAll) {
        setSelectedDevices([]);
        setPrevSelectedAll(false);
      } else {
        const selected = typeof value === 'string' ? value.split(',') : value;
        setSelectedDevices(selected);
      }
    }
  };

  const handleSubmit = async () => {
    if (title === 'Delta Histogram') {
      setNeedRecursion({ ...needRecursion, delta: false });
    } else if (title === 'Signal Strength Histogram') {
      setNeedRecursion({ ...needRecursion, signal: false });
    }
    setSelectedDropDownValue(recentOptions[0]);
    setAllData([]);

    setIsPopoverOpen(false);
    setIsLoading(true);
    await getDataFromAPI(fromTime.toISOString(), toTime.toISOString());
    setIsLoading(false);
  };

  const handleDropDownChange = async (e) => {
    setSelectedDropDownValue(e.target.value);
    setAllData([]);
    if (title === 'Delta Histogram') {
      setNeedRecursion({ ...needRecursion, delta: false });
    } else if (title === 'Signal Strength Histogram') {
      setNeedRecursion({ ...needRecursion, signal: false });
    }
    if (e.target.value !== '--SELECT--') {
      let hourCount;
      if (e.target.value === 'Last 1 hour') {
        hourCount = 1;
      } else if (e.target.value === 'Last 2 hours') {
        hourCount = 2;
      }
      const to = new Date();
      const from = new Date(new Date() - hourCount * 60 * 60 * 1000);
      setIsLoading(true);
      await getDataFromAPI(from.toISOString(), to.toISOString());
      setIsLoading(false);
    }
  };

  const [data, setData] = useState([]);
  const [allData, setAllData] = useState([...buckets]);
  const [isFilterDisabled, setIsFilterDisabled] = useState(false);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      setCurrentTime(new Date());
      const ft = new Date(fromTime);
      const six = new Date(ft.setHours(ft.getHours() + 6));
      if (six < currentTime) {
        maxTime.current = new Date(six);
      } else {
        maxTime.current = currentTime;
      }

      if (toTime > six) {
        setIsFilterDisabled(true);
      } else {
        setIsFilterDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTime, toTime]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (title === 'Delta Histogram' && needRecursion.delta) {
      setAllData(recursiveData);
      setIsLoading(isInitialLoading);
    } else if (title === 'Signal Strength Histogram' && needRecursion.delta) {
      setAllData(recursiveData);
      setIsLoading(isInitialLoading);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recursiveData, isInitialLoading]);

  useEffect(() => {
    const dataItems = Data;
    let total = 0;

    if (allData.length > 0) {
      const filteredDataItems = allData.filter((item) => {
        if (selectedDevices.includes(item.index)) total++;
        return selectedDevices.includes(item.index);
      });

      filteredDataItems.forEach((item) => {
        let index = 0;
        for (let bucket of Buckets) {
          if (type === 'delta') {
            if (bucket.upperLimit === null) {
              if (item.delta > bucket.lowerLimit) {
                dataItems[index] = {
                  ...dataItems[index],
                  count: dataItems[index].count + 1,
                  percentage: ((dataItems[index].count + 1) / total) * 100,
                };
                break;
              }
            } else if (
              item.delta >= bucket.lowerLimit &&
              item.delta <= bucket.upperLimit
            ) {
              dataItems[index] = {
                ...dataItems[index],
                count: dataItems[index].count + 1,
                percentage: ((dataItems[index].count + 1) / total) * 100,
              };
              break;
            }
            index++;
          } else if (type === 'signal') {
            if (bucket.upperLimit === null) {
              if (item.signal_strength > bucket.lowerLimit) {
                dataItems[index] = {
                  ...dataItems[index],
                  count: dataItems[index].count + 1,
                  percentage: ((dataItems[index].count + 1) / total) * 100,
                };
                break;
              }
            } else if (item.signal_strength <= bucket.upperLimit) {
              dataItems[index] = {
                ...dataItems[index],
                count: dataItems[index].count + 1,
                percentage: ((dataItems[index].count + 1) / total) * 100,
              };
              break;
            }
            index++;
          }
        }
      });

      setData(dataItems);
    } else setData([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevices, allData]);

  async function getDataFromAPI(from, to) {
    try {
      const response = await axios.get(
        `${BASE_URL}/api/histogram?from_date=${from}&to_date=${to}`,
        {
          headers: {
            'x-access-token': token,
          },
        }
      );

      if (response.data.data.length > 0) {
        const responseData = response.data.data;
        setAllData(responseData);
      } else setAllData([]);
    } catch (e) {
      setIsError(true);
    }
  }

  return (
    <Box
      sx={{
        background: color,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100vh',
        position: 'relative',
        justifyContent: 'space-evenly',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          background: '#fff',

          borderRadius: '.2em',
          boxShadow: '1px 2px 10px rgba(0,0,0,0.2)',
          width: '80%',
          alignItems: 'center',
          justifyContent: 'space-evenly',

          height: '5rem',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
            width: '90%',
            py: 1,
          }}
        >
          <Typography
            sx={{
              textAlign: 'center',
              textTransform: 'uppercase',
              fontSize: '1.5em',
              fontWeight: 500,
            }}
            variant='body1'
          >
            {title}
          </Typography>
          <Divider orientation='vertical' flexItem />
          <Box
            sx={{
              width: '50%',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
            }}
          >
            <Button variant='contained' onClick={handlePopover}>
              Filter By Range
            </Button>{' '}
            <Popover
              open={popoverOpen}
              anchorEl={isPopoverOpen}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '15rem',
                  justifyContent: 'space-evenly',
                  px: 2,
                  pt: 2,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size='small' />
                    )}
                    label='From'
                    value={fromTime}
                    maxDateTime={currentTime}
                    onChange={(newValue) => {
                      setFromTime(newValue);
                    }}
                  />

                  <DateTimePicker
                    renderInput={(props) => (
                      <TextField {...props} size='small' />
                    )}
                    label='To'
                    value={toTime}
                    minDateTime={fromTime}
                    maxDateTime={maxTime.current}
                    onChange={setToTime}
                  />
                </LocalizationProvider>
                <Button
                  aria-label='search'
                  variant='contained'
                  size='large'
                  sx={{
                    backgroundColor: 'rgba(42, 129, 226,1)',
                    color: 'white',
                    '&:hover': {
                      backgroundColor: 'rgba(42, 129, 226,1)',
                      color: 'white',
                    },
                  }}
                  disabled={isFilterDisabled}
                  onClick={handleSubmit}
                >
                  Filter
                </Button>
              </Box>
            </Popover>
            <FormControl
              sx={{
                m: 1,
                margin: 1,
                width: 120,
              }}
            >
              <InputLabel id='demo-multiple-checkbox-label' size='small'>
                Device
              </InputLabel>
              <Select
                multiple
                value={selectedDevices}
                onChange={handleDeviceSelect}
                input={<OutlinedInput label='Device' size='small' />}
                renderValue={(selected) => {
                  if (!selected.includes('All')) return selected.join(', ');
                  else return 'All';
                }}
                MenuProps={MenuProps}
              >
                {deviceNames.map((name) => (
                  <MenuItem key={name} value={name}>
                    <Checkbox checked={selectedDevices.indexOf(name) > -1} />
                    <ListItemText primary={name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl size='small' sx={{ width: '150px' }}>
              <InputLabel id='demo-simple-select-label'>Recent</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={selectedDropDownValue}
                label='Recent'
                onChange={handleDropDownChange}
                defaultValue={recentOptions[0]}
              >
                {recentOptions.map((item, index) => (
                  <MenuItem key={index} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>
      </Box>

      <BarChart
        allData={allData}
        data={data}
        isLoading={isLoading}
        title={title}
        error={isError}
      />
    </Box>
  );
}
