import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import EditIcon from "@mui/icons-material/Edit";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { getRegionsAsync } from "../../../Redux/regionsSlice";
import { TextField } from "@mui/material";

const headCells = [
  {
    id: "dma_rank",
    numeric: true,
    disablePadding: true,
    label: "DMA Rank",
  },
  {
    id: "dma_name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "dma_code",
    numeric: false,
    disablePadding: false,
    label: "DMA Code",
  },
  {
    id: "lat",
    numeric: false,
    disablePadding: false,
    label: "Centroid Latitude",
  },
  {
    id: "lon",
    numeric: false,
    disablePadding: false,
    label: "Centroid Longitude",
  },
  {
    id: "change",
    numeric: true,
    disablePadding: false,
    label: "Change",
  },
];

function EnhancedTableHead(props) {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "center" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  rowCount: PropTypes.number.isRequired,
};

const EnhancedTableToolbar = (props) => {
  const { addForm, search, setSearch, setPage } = props;
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
      }}
    >
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h6"
        id="tableTitle"
        component="div"
      >
        Regions
      </Typography>
      <TextField
        id="search-regions"
        label="Search"
        variant="standard"
        placeholder="Enter Name"
        value={search}
        onChange={(e) => {
          setPage(0);
          setSearch(e.target.value);
        }}
      />
      <Tooltip title="Add Region">
        <IconButton onClick={addForm}>
          <AddIcon />
        </IconButton>
      </Tooltip>
    </Toolbar>
  );
};

export default function RegionsTable(props) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const dispatch = useDispatch();
  const {
    addRegionFormOpen,
    editRegionFormOpen,
    confirmDeleteOpen,
    setConfirmDeleteID,
    token,
  } = props;
  const regions = useSelector((state) => state.regions.regions);
  let rows = regions;
  const [search, setSearch] = useState("");

  if (search === "") {
    rows = regions;
  } else {
    rows = regions.filter((region) => {
      return region.dma_name.toLowerCase().includes(search.toLowerCase());
    });
  }

  useEffect(() => {
    dispatch(getRegionsAsync({ token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleEditRegionFormOpen = (id) => {
    editRegionFormOpen(id);
  };

  const handleDelete = (id) => {
    setConfirmDeleteID(id);
    confirmDeleteOpen();
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page >= 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  return (
    <Box
      sx={{
        width: "100%",
        height: "90%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Paper sx={{ width: "90%", mb: 2 }} elevation={5}>
        <EnhancedTableToolbar
          addForm={addRegionFormOpen}
          search={search}
          setSearch={setSearch}
          setPage={setPage}
        />
        <TableContainer sx={{ maxHeight: 440 }}>
          <Table
            stickyHeader
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <EnhancedTableHead rowCount={rows.length} />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow hover tabIndex={-1} key={row.id}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        align="center"
                      >
                        {row.dma_rank}
                      </TableCell>
                      <TableCell align="left">{row.dma_name}</TableCell>
                      <TableCell align="left">{row.dma_code}</TableCell>
                      <TableCell align="left">
                        {row.dma_centroid &&
                          row.dma_centroid
                            .replace("POINT ", "")
                            .split(" ")[0]
                            .slice(1)}
                      </TableCell>
                      <TableCell align="left">
                        {row.dma_centroid &&
                          row.dma_centroid
                            .replace("POINT ", "")
                            .split(" ")[1]
                            .slice(0, -1)}
                      </TableCell>
                      <TableCell align="center">
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => handleEditRegionFormOpen(row)}
                            >
                              <EditIcon />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                handleDelete(row.id);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
