import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const axios = require("axios");

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const approveUsersAsync = createAsyncThunk(
  "users/approveUsersAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.patch(
        `${BASE_URL}/api/auth/user/${payload.id}`,
        {
          isApproved: payload.isApproved,
          isActive: payload.isApproved,
        },
        { headers: { "x-access-token": token } }
      );
      return payload;
    } catch (err) {
      throw err;
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "users/changePasswordAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.patch(
        `${BASE_URL}/api/auth/user/changePassword/${payload.id}`,
        {
          password: payload.password,
        },
        {
          headers: {
            "x-access-token": token,
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
          },
        }
      );
      return "Password Changed Successfully";
    } catch (err) {
      rejectWithValue("Failed to Change Password, Try Again!");
      throw err;
    }
  }
);

export const getUsersAsync = createAsyncThunk(
  "users/getUsersAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const usersResponse = await axios.get(
        `${BASE_URL}/api/auth/getUserList`,
        { headers: { "x-access-token": token } }
      );
      return usersResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const signUpUserAsync = createAsyncThunk(
  "users/signUpUserAsync",
  async (payload) => {
    try {
      await axios.post(`${BASE_URL}/api/auth/signup`, {
        username: payload.username,
        email: payload.email,
        password: payload.password,
        isActive: false,
        isApproved: null,
        roles: ["user"],
      });
    } catch (err) {
      throw err;
    }
  }
);

export const addUserAsync = createAsyncThunk(
  "users/addUserAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.post(
        `${BASE_URL}/api/auth/userCreation`,
        {
          username: payload.username,
          email: payload.email,
          password: payload.password,
          roles: ["user"],
        },
        { headers: { "x-access-token": token } }
      );

      return {
        ...payload,
        id: payload.username,
        isApproved: true,
        isActive: true,
      };
    } catch (err) {
      return rejectWithValue("Couldn't Add User. Try Again!", err);
    }
  }
);

export const toggleActiveAsync = createAsyncThunk(
  "users/toggleActiveAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.patch(
        `${BASE_URL}/api/auth/user/${payload.id}`,
        {
          isActive: payload.isActive,
        },
        {
          headers: {
            "x-access-token": token,
            "Content-type": "application/json",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
            "Access-Control-Allow-Credentials": true,
            "Access-Control-Allow-Headers":
              "Access-Control-Allow-Headers, Origin,Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers",
          },
        }
      );

      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't Add User. Try Again!", err);
    }
  }
);

export const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [],
  },
  reducers: {
    toggleUncorrected: (state, action) => {
      return { ...state, uncorrected: action.payload.uncorrected };
    },
  },
  extraReducers: {
    [getUsersAsync.fulfilled]: (state, action) => {
      return { ...state, users: action.payload.data };
    },
    [addUserAsync.fulfilled]: (state, action) => {
      return { ...state, users: [...state.users, action.payload] };
    },

    [toggleActiveAsync.fulfilled]: (state, action) => {
      const toggledUserIndex = state.users.findIndex(
        (user) => user.id === action.payload.id
      );
      const updatedUsers = state.users;

      if (toggledUserIndex > -1) {
        updatedUsers[toggledUserIndex] = {
          ...updatedUsers[toggledUserIndex],
          isActive: action.payload.isActive,
        };
      }
    },
    [approveUsersAsync.fulfilled]: (state, action) => {
      const approvedUserIndex = state.users.findIndex(
        (user) => user.id === action.payload.id
      );
      const updatedUsers = state.users;

      if (approvedUserIndex > -1) {
        updatedUsers[approvedUserIndex] = {
          ...updatedUsers[approvedUserIndex],
          isApproved: action.payload.isApproved,
          isActive: action.payload.isApproved,
        };
      }
    },
  },
});

export const { toggleUncorrected } = usersSlice.actions;

export default usersSlice.reducer;
