import React, { useState, useEffect } from "react";
import AddDeviceModal from "./AddDeviceModal";
import EditDeviceModal from "./EditDeviceModal";
import MapDeviceToRegion from "./MapDeviceToRegionModal";
import DevicesTable from "./DevicesTable";
import { useDispatch } from "react-redux";
import { getRegionsAsync } from "../../../Redux/regionsSlice";
import ConfirmDialog from "./ConfirmDialog";
import Snackbar from "../../../Components/Snackbar/Snackbar";

export default function Devices({ token }) {
  const [addDeviceFormOpen, setAddDeviceFormOpen] = useState(false);
  const [editDeviceFormOpen, setEditDeviceFormOpen] = useState(false);
  const [mapDeviceFormOpen, setMapDeviceFormOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    msg: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarProps({ ...snackbarProps, open: false });
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getRegionsAsync({ token }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [editFormData, setEditFormData] = useState({
    name: "",
    host: "",
    driverName: "",
    region: "",
    regionID: "",
    newRegion: "",
  });

  const [addFormData, setAddFormData] = useState({
    name: "",
    host: "",
    driverName: "",
    region: "None",
    regionID: "",
  });

  const [mapFormData, setMapFormData] = useState({
    name: "",
    region: "None",
  });

  const [confirmDeleteID, setConfirmDeleteID] = useState(null);

  const handleAddClickOpen = () => {
    setAddDeviceFormOpen(true);
  };

  const handleAddClose = () => {
    setAddDeviceFormOpen(false);
  };

  const handleMapClickOpen = (row) => {
    setMapFormData({ ...mapFormData, name: row.name });
    setMapDeviceFormOpen(true);
  };

  const handleMapClose = () => {
    setMapDeviceFormOpen(false);
  };

  const handleEditClickOpen = (row) => {
    setEditDeviceFormOpen(true);
    setEditFormData({
      name: row.name,
      host: row.host,
      driverName: row.driverName,
      region: row.regions.length ? row.regions[0].dma_name : "None",
      regionID: row.regions.length && row.regions[0].id,
    });
  };

  const handleEditClose = () => {
    setEditDeviceFormOpen(false);
  };

  const handleConfirmOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <>
      <AddDeviceModal
        handleClickOpen={handleAddClickOpen}
        handleClose={handleAddClose}
        open={addDeviceFormOpen}
        addFormData={addFormData}
        setAddFormData={setAddFormData}
        setSnackbarProps={setSnackbarProps}
      />
      <EditDeviceModal
        handleClickOpen={handleEditClickOpen}
        handleClose={handleEditClose}
        open={editDeviceFormOpen}
        editFormData={editFormData}
        setEditFormData={setEditFormData}
        setSnackbarProps={setSnackbarProps}
      />
      <DevicesTable
        addDeviceFormOpen={handleAddClickOpen}
        editDeviceFormOpen={handleEditClickOpen}
        mapDeviceFormOpen={handleMapClickOpen}
        confirmDeleteOpen={handleConfirmOpen}
        setConfirmDeleteID={setConfirmDeleteID}
        token={token}
      />
      <MapDeviceToRegion
        handleClickOpen={handleMapClickOpen}
        handleClose={handleMapClose}
        open={mapDeviceFormOpen}
        mapFormData={mapFormData}
        setMapFormData={setMapFormData}
        setSnackbarProps={setSnackbarProps}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        id={confirmDeleteID}
        handleClose={handleConfirmClose}
        setSnackbarProps={setSnackbarProps}
      />
      <Snackbar
        snackbarOpen={snackbarProps.open}
        msg={snackbarProps.msg}
        severity={snackbarProps.severity}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}
