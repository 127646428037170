import React, { useRef, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { addDeviceInfo } from '../../Redux/dataSlice';
import MapContainer from './StyledComponents/MapContainer';
import StyledSidebar from './StyledComponents/StyledSidebar';
import MapInfoBox from './MapInfoBox';
import car from '../../Assets/Images/car.png';
import dot from '../../Assets/Images/dot.png';
import { convertJSONToString, getCoordinates, showPopUp } from './Helpers';
import mapboxgl from 'mapbox-gl';
import './popUpStyles.css';


mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

const BASE_URL = process.env.REACT_APP_BASE_URL;

function LiveMap() {
  const axios = require('axios');
  const token=useSelector(state=>state.auth.token);

  const deltaColors = useSelector((state) => state.carColor.deltaColors);

  const getCarColor = (delta) => {
    delta = parseFloat(delta);
    let i = 0;
    for (let bin of deltaColors) {
      i++;
      if (bin.range_from <= delta && delta <= bin.range_to) {
        return bin.color;
      }
    }
    if (i === deltaColors.length) return '#026ced';
  };

  const dispatch = useDispatch();

  const {
    setIntervalAsync,
    clearIntervalAsync,
  } = require('set-interval-async/dynamic');

  const initialMount = useRef();
  initialMount.current = true;

  const mapContainerRef = useRef(null);
  const [lng, setLng] = useState(5);
  const [lat, setLat] = useState(34);
  const [zoom, setZoom] = useState(1.5);
  const uncorrectedState = useRef(true);
  const onlyLastCoordinate = useRef(false);
  const sourcesDestinations = useRef([]);

  const devices = useRef();
  devices.current = useSelector((state) => state.data.devices).map(
    (device) => device.name
  );

  const allDevices = useRef();
  const allD = [];
  allDevices.current = devices.current.forEach((device) => {
    allD.push(device);
    allD.push(device + ' U');
  });

  allDevices.current = allD;

  const selectedDevices = useRef();
  selectedDevices.current = useSelector((state) => state.data.selectedDevices);

  uncorrectedState.current = useSelector((state) => state.data.uncorrected);

  onlyLastCoordinate.current = useSelector(
    (state) => state.data.onlyLastCoordinate
  );

  useEffect(() => {
    let isFalse = false;
    let updateSource;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      zoom: 0,
    });

    map.addControl(new mapboxgl.NavigationControl(), 'top-right');

    map.on('move', () => {
      setLng(map.getCenter().lng.toFixed(4));
      setLat(map.getCenter().lat.toFixed(4));
      setZoom(map.getZoom().toFixed(2));
    });

    map.on('load', async () => {
      let geojson, lineFeatures;
      const promises = [getLocation(), getRoutes()];
      await Promise.all(promises).then((res) => {
        geojson = res[0];
        lineFeatures = res[1];
      });

      map.loadImage(car, (error, image) => {
        if (error) throw error;
        map.addImage('car-icon', image, { sdf: true });
      });

      map.loadImage(dot, (error, image) => {
        if (error) throw error;
        map.addImage('dot-icon', image, { sdf: true });
      });

      map.addSource('atsc-device', {
        type: 'geojson',
        data: geojson,
      });

      map.addLayer({
        id: 'atsc-device',
        type: 'symbol',
        source: 'atsc-device',
        layout: {
          'icon-image': ['get', 'icon'],
          'icon-size': ['get', 'iconsize'],
          'icon-allow-overlap': true,
          'text-allow-overlap': true,
          'text-field': ['get', 'title'],
          'text-font': ['Open Sans Semibold', 'Arial Unicode MS Bold'],
          'text-offset': [0, 1],
          'text-anchor': 'top',
        },
        filter: ['==', 'type', 'Point'],
        paint: {
          'icon-color': ['get', 'CAR_TYPE'],
        },
      });

      map.addSource('route', {
        type: 'geojson',
        data: lineFeatures,
      });

      map.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round',
        },
        paint: {
          'line-color': ['get', 'color'],
          'line-width': 1,
          'line-opacity': 0.75,
        },
      });

      updateSource = setIntervalAsync(async () => {
        let geojson;
        const promises = [getLocation(updateSource)];
        await Promise.all(promises).then((res) => {
          geojson = res[0];
        });

        if (!isFalse) {
          map.getSource('atsc-device').setData(geojson);
        }
      }, 1000);

      const runOnlyOnce = async () => {
        try {
          const [lat, lon] = [45.512230, -122.658722];
          map.flyTo({
            center: [lon, lat],
            speed: 0.5,
            zoom: 7,
          });
        } catch (error) {}
      };

      if (initialMount) {
        runOnlyOnce();
        initialMount.current = false;
      }

      async function getLocation(updateSource) {
        try {
          const response = await axios.get(`${BASE_URL}/api/mapData`, {
            headers: {
              "x-access-token": token,
            },
          });

          const responseData = response.data;
    

          if (onlyLastCoordinate.current) {
            // Do something when they choose last coordinate only
            // Get only the last coordinate

            const devices = [];
            const filteredResponseData = [];
            responseData.data.forEach((device) => {
              if (!devices.includes(device.index)) {
                devices.push(device.index);
                filteredResponseData.push(device);
              }
            });
            responseData.data.splice(0, responseData.data.length);
            responseData.data.push(...filteredResponseData);
          }

          const deviceInfo = [];

          responseData.signal.forEach((device) => {
            responseData.euclidian_mean_display.forEach((ed) => {
              if (ed.device === device.device)
                deviceInfo.push({
                  ...device,
                  euclideanDistance: ed.euclidean_distance,
                });
            });
          });

          if (responseData.fixtype) {
            deviceInfo.forEach((device, index) => {
              const fixType = responseData.fixtype.find(
                (ft) => ft.device === device.device
              );
              if (fixType) {
                deviceInfo[index] = {
                  ...device,
                  fixType: fixType.fix_type_name,
                };
              } else {
                deviceInfo[index] = { ...device, fixType: '-' };
              }
            });
          } else {
            deviceInfo.forEach((device, index) => {
              deviceInfo[index] = { ...device, fixType: '-' };
            });
          }

          dispatch(addDeviceInfo({ data: deviceInfo }));

          const sd = [];

          const geoData = {};

          if (map.getSource('atsc-device')) {
            if (!selectedDevices.current.includes('All')) {
              const devicesToDisplay = [];
              selectedDevices.current.forEach((device) => {
                devicesToDisplay.push(device);
                devicesToDisplay.push(device + ' U');
              });

              if (uncorrectedState.current) {
                map.setFilter('atsc-device', [
                  'in',
                  'index',
                  ...devicesToDisplay,
                ]);

                map.setFilter('route', ['in', 'index', ...devicesToDisplay]);
              } else {
                map.setFilter('atsc-device', [
                  'in',
                  'index',
                  ...selectedDevices.current,
                ]);
                map.setFilter('route', [
                  'in',
                  'index',
                  ...selectedDevices.current,
                ]);
              }
            } else {
              if (uncorrectedState.current) {
                map.setFilter('atsc-device', [
                  'in',
                  'index',
                  ...allDevices.current,
                ]);
                map.setFilter('route', ['in', 'index', ...allDevices.current]);
              } else {
                map.setFilter('atsc-device', [
                  'in',
                  'index',
                  ...devices.current,
                ]);
                map.setFilter('route', ['in', 'index', ...devices.current]);
              }
            }
          }

          responseData.data.forEach((data) => {
            if (data.index in geoData) {
              geoData[data.index] = [...geoData[data.index], data];
            } else {
              geoData[data.index] = [data];
            }
          });

          const feats = [];

          Object.values(geoData).forEach((collection) => {
            collection.forEach((feature) => {
              let lastIndex = 0;
              let isLast = collection.indexOf(feature) === lastIndex;

              if (isLast) {
                sd.push([
                  [collection[0].lon, collection[0].lat],
                  [feature.lon, feature.lat],
                  feature.colour,
                  feature.index,
                ]);
              }

              feats.push({
                type: 'Feature',
                properties: {
                  title: isLast
                    ? feature.index + ' ' + feature.driverName || 'Null'
                    : '',
                  index: feature.index,
                  icon: isLast ? 'car-icon' : 'dot-icon',
                  iconsize: isLast ? 0.1 : 0.2,
                  CAR_TYPE: getCarColor(feature.device_stats.delta.split(' ')),
                  description: convertJSONToString(feature.device_stats),
                },
                geometry: {
                  type: 'Point',
                  coordinates: [feature.lon, feature.lat],
                },
              });
            });
          });

          sourcesDestinations.current = sd;

          showPopUp(map, 'atsc-device');

          return {
            type: 'FeatureCollection',
            features: feats,
          };
        } catch (err) {
          // if (updateSource) clearIntervalAsync(updateSource);
          console.log(err);
        }
      }

      async function getRoutes(updateSource) {
        try {
          const promises = [];
          sourcesDestinations.current.forEach((device) => {
            promises.push(
              getCoordinates(device[0], device[1], device[2], device[3])
            );
          });

          const lineFeatures = await Promise.all(promises);

          return {
            type: 'FeatureCollection',
            features: lineFeatures,
          };
        } catch (err) {
          if (updateSource) clearIntervalAsync(updateSource);
          throw new Error(err);
        }
      }
    });

    return () => {
      isFalse = true;
      if (updateSource) clearIntervalAsync(updateSource);
      map.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <>
        <StyledSidebar>
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
        </StyledSidebar>
        <MapContainer ref={mapContainerRef} />
        <MapInfoBox />
      </>
    </>
  );
}

export default LiveMap;
