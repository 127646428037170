import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, Autocomplete } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { addDeviceAsync } from "../../../Redux/devicesSlice";

export default function AddDeviceModal(props) {
  const { handleClose, open, addFormData, setAddFormData, setSnackbarProps } =
    props;
  const regions = useSelector((state) => state.regions.regions);

  const options = ["None", ...regions.map((region) => region.dma_name)];
  const [inputValue, setInputValue] = useState("None");
  const [error] = useState("");
  const dispatch = useDispatch();

  const handleAddFormData = (evt) => {
    const { name, value } = evt.target;
    setAddFormData({ ...addFormData, [name]: value });
  };

  const handleRegionChange = async (event, newValue) => {
    if (!newValue) {
      setAddFormData({
        ...addFormData,
        region: "",
        regionID: "",
      });
      return;
    }
    setAddFormData({
      ...addFormData,
      region: newValue,
      regionID:
        newValue !== "None"
          ? regions.filter((region) => region.dma_name === newValue)[0].id
          : "",
    });
  };

  const handleAddDevice = async () => {
    await dispatch(addDeviceAsync({ ...addFormData }))
      .unwrap()
      .then(() => {
        setSnackbarProps({
          open: true,
          severity: "success",
          msg: "Added device successfully",
        });
        handleClose();
      })
      .catch((err) => {
        return setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Failed to add device, Try again!",
        });
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={{ height: "100%" }}>
        <DialogTitle>Add Device</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="device_name"
            name="name"
            label="Device Name"
            type="text"
            onChange={handleAddFormData}
            value={addFormData.name}
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="device_host"
            name="host"
            label="Device Host"
            type="text"
            value={addFormData.host}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />

          <TextField
            margin="dense"
            id="device_drivwe"
            name="driverName"
            label="Driver Name"
            type="text"
            value={addFormData.driverName}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />

          <Typography>Map Device to Region</Typography>
          <Box sx={{ my: 1, maxWidth: "200px" }}>
            <Autocomplete
              value={addFormData.region}
              onChange={handleRegionChange}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  name="region"
                  label="Region"
                />
              )}
              disableClearable
            />
          </Box>
          <Typography>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddDevice}>Add</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
