import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  setDataInLocal,
  setAuthToken,
  removeAuthToken,
  removeDataInLocal,
  setRole,
  getAuthToken,
  getRole,
  getDataInLocal,
} from '../Helpers/deps';
import axios from 'axios';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const loginUserAsync = createAsyncThunk(
  'auth/loginUserAsync',
  async (payload, { rejectWithValue }) => {
    const url = `${BASE_URL}/api/auth/signin`;
    try {
      const response = await axios.post(url, {
        email: payload.email,
        password: payload.password,
      });
      if (response.status === 200) {
        const { accessToken, roles } = response.data;
        if (payload.remember) {
          setDataInLocal('user', response.data);
          setAuthToken(accessToken);
          setRole(roles[0]);
        }
        return response.data;
      }
    } catch (error) {
      return rejectWithValue('Invalid Credentials!');
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  'auth/resetPasswordAsync',
  async (payload, { rejectWithValue }) => {
    const reset_url =
      'https://backend-dot-net-zero-pathways.ts.r.appspot.com/api/passwordReset';
    try {
      const response = await axios.post(reset_url, {
        // nsw.admin@nsw.com
        email: payload.email,
      });

      const token = response.data.link.split(
        'http://nsw.35.224.40.3/auth/reset?token='
      )[1];
      return { token };
    } catch (error) {
      return rejectWithValue('Invalid Email');
    }
  }
);

export const authSlice = createSlice({
  name: 'auth',
  initialState: { isLoggedIn: false, isSessionExpired: false },
  reducers: {
    setLogIn: (state, action) => {
      const setLoggedIn = {
        isLoggedIn: true,
        token: getAuthToken().token,
        isAdmin: getRole().includes('ROLE_ADMIN'),
        ...getDataInLocal(),
      };

      return setLoggedIn;
    },
    logOut: (state, action) => {
      removeDataInLocal('user');
      removeAuthToken('user_token');
      return { isLoggedIn: false,isSessionExpired:false };
    },
    logOutOnSessionExpire: (state, action) => {
      removeDataInLocal('user');
      removeAuthToken('user_token');
      return {...state, isSessionExpired: true };
    },
  },
  extraReducers: {
    [loginUserAsync.fulfilled]: (state, action) => {
      if (action.payload) {
        const setLoggedIn = {
          isLoggedIn: true,
          token: action.payload.accessToken,
          isAdmin: action.payload.roles.includes('ROLE_ADMIN'),
          ...action.payload,
        };
        return setLoggedIn;
      } else {
        return state;
      }
    },
  },
});

export const { logOut, setLogIn, logOutOnSessionExpire } = authSlice.actions;

export default authSlice.reducer;
