import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  TextField,
  OutlinedInput,
  Checkbox,
  ListItemText,
  FormControlLabel,
  Switch,
  Autocomplete,
  Button,
} from "@mui/material";
import { styled } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllDevicesAsync,
  getHistoryRegionDevicesAsync,
  selectHistoryDevices,
  selectHistoryRegion,
  toggleHistoryUncorrected,
} from "../../Redux/dataSlice";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import TimePicker from "@mui/lab/TimePicker";
import DateTimePicker from "@mui/lab/DateTimePicker";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 3.5 + ITEM_PADDING_TOP,
      width: "100px",
    },
  },
};

const StyledBox = styled(Box, { name: "StyledBox", slot: "Wrapper" })({
  gridArea: "sidebar",
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  maxWidth: "260px",
});

export default function HistorySidebar(props) {
  const { setIsLoading, getHistoryData, isLoading } = props;
  const devices = useSelector((state) =>
    state.data.historyDevices.map((device) => device.name)
  );
  let deviceNames = [];
  if (devices.length > 0) deviceNames = ["All", ...devices];
  const selectedHistoryDevices = useSelector(
    (state) => state.data.selectedHistoryDevices
  );
  const initialLoad = useRef(true);
  const [prevSelectedAll, setPrevSelectedAll] = useState(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [fromTime, setFromTime] = useState(
    new Date(new Date() - 15 * 60 * 1000)
  );
  const [toTime, setToTime] = useState(currentTime);
  const maxTime = useRef(currentTime);

  useEffect(() => {
    dispatch(
      selectHistoryDevices({ selectedHistoryDevices: ["All", ...devices] })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      setCurrentTime(new Date());
      const ft = new Date(fromTime);
      const two = new Date(ft.setHours(ft.getHours() + 2) + 1000 * 60);
      if (two < currentTime) {
        maxTime.current = new Date(two);
      } else {
        maxTime.current = currentTime;
      }

      if (toTime > two) {
        setIsFilterDisabled(true);
      } else {
        setIsFilterDisabled(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTime, toTime]);

  const regions = useSelector((state) => state.data.regions);

  const [isFilterDisabled, setIsFilterDisabled] = useState(false);

  const options = [
    "All",
    ...regions.map((region) => region.dma_rank + " " + region.dma_name),
  ];

  const [inputValue, setInputValue] = useState("Select Region");
  const [value, setValue] = useState(options[0]);

  const dispatch = useDispatch();
  const showUncorrected = useSelector((state) => state.data.historyUncorrected);

  const handleUncorrected = (event) => {
    const uncorrected = event.target.checked;
    dispatch(toggleHistoryUncorrected({ uncorrected }));
  };
  const handleRegionChange = async (event, newValue) => {
    if (!newValue) {
      setValue("");
      return;
    }
    setValue(newValue);
    if (newValue === "All") {
      await dispatch(getAllDevicesAsync());
    } else {
      const id = regions.filter(
        (region) => region.dma_rank === parseInt(newValue.split(" ")[0])
      )[0].id;
      await dispatch(selectHistoryRegion({ region: id }));

      await dispatch(
        getHistoryRegionDevicesAsync({
          id: id,
        })
      )
        .unwrap()
        .then(async (payload) => {
          await dispatch(
            selectHistoryDevices({
              selectedHistoryDevices: [
                "All",
                ...payload.map((device) => device.name),
              ],
            })
          );
        });
    }
  };
  const handleDeviceSelect = async (event) => {
    const {
      target: { value },
    } = event;
    if (value.includes("All")) {
      setPrevSelectedAll(true);
      await dispatch(
        selectHistoryDevices({ selectedHistoryDevices: ["All", ...devices] })
      );
    } else {
      if (prevSelectedAll) {
        await dispatch(selectHistoryDevices({ selectedHistoryDevices: [] }));
        setPrevSelectedAll(false);
      } else {
        const selected = typeof value === "string" ? value.split(",") : value;
        await dispatch(
          selectHistoryDevices({ selectedHistoryDevices: selected })
        );
      }
    }
  };
  const handleTimeSearch = async () => {
    setIsLoading(true);
    await getHistoryData(fromTime, toTime);

    // await dispatch(getHistoryDataAsync({ from, to }))
    //   .unwrap()
    //   .then(() => {
    //     setIsLoading(false);
    //   })
    //   .catch((error) => {
    //     throw error;
    //   });
  };

  return (
    <StyledBox>
      <Box
        sx={{
          my: 1,
          py: 1,
          px: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography>Time Range</Typography>
        </Box>
        <Box
          sx={{
            my: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DateTimePicker
              renderInput={(props) => <TextField {...props} size="small" />}
              label="From"
              value={fromTime}
              maxDateTime={currentTime}
              onChange={(newValue) => {
                setFromTime(newValue);
              }}
            />

            <DateTimePicker
              renderInput={(props) => (
                <TextField {...props} size="small" sx={{ mt: 2 }} />
              )}
              label="To"
              value={toTime}
              minDateTime={fromTime}
              maxDateTime={maxTime.current}
              onChange={setToTime}
            />
          </LocalizationProvider>
        </Box>

        <Button
          size="small"
          variant="contained"
          sx={{ mb: 1 }}
          fullWidth
          onClick={handleTimeSearch}
          disabled={isFilterDisabled || isLoading}
        >
          Search
        </Button>
      </Box>
      <Box sx={{ p: 2 }}>
        <Typography>Filter Region</Typography>
        <Box sx={{ my: 1, maxWidth: "200px" }}>
          <Autocomplete
            value={value}
            onChange={handleRegionChange}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            id="controllable-states-demo"
            options={options}
            renderInput={(params) => (
              <TextField {...params} size="small" label="Region" />
            )}
          />
        </Box>
      </Box>

      <Box sx={{ py: 1, px: 2 }}>
        <Typography>Filter Devices</Typography>
        <Box
          sx={{
            my: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FormControl
            sx={{
              minWidth: "calc(100%)",
              maxWidth: 100,
            }}
          >
            <InputLabel id="demo-multiple-checkbox-label" size="small">
              Device
            </InputLabel>
            <Select
              multiple
              value={selectedHistoryDevices}
              onChange={handleDeviceSelect}
              input={<OutlinedInput label="Device" size="small" />}
              renderValue={(selected) => {
                if (!selected.includes("All")) return selected.join(", ");
                else return "All";
              }}
              MenuProps={MenuProps}
            >
              {deviceNames.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox
                    checked={selectedHistoryDevices.indexOf(name) > -1}
                  />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          my: 1,
          p: 2,
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={showUncorrected}
              onChange={handleUncorrected}
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label={<Typography variant="body2">Show Uncorrected</Typography>}
        />
      </Box>
    </StyledBox>
  );
}
