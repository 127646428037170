import { Box } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import Chart from '../Chart/Chart';
import { useSelector } from 'react-redux';
import LiveMap from './LiveMap';
import axios from 'axios';

import Sidebar from './Sidebar';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function Live() {
  const initialLoad = useRef(true);
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [needRecursion, setNeedRecursion] = useState({
    delta: true,
    signal: true,
  });
  const [recursiveData, setRecursiveData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const {
    setIntervalAsync,
    clearIntervalAsync,
  } = require('set-interval-async/dynamic');

  useEffect(() => {
    if (initialLoad.current) {
      const to = new Date();
      const from = new Date(to - 1000 * 60 * 60);
      initialLoad.current = false;
      try {
        axios
          .get(
            `${BASE_URL}/api/histogram?from_date=${from.toISOString()}&to_date=${to.toISOString()}`,
            {
              headers: {
                "x-access-token": token,
              },
            }
          )
          .then((response) => {
            if (response.data.data.length > 0) {
              const responseData = response.data.data;
              setRecursiveData(responseData);
              setIsInitialLoading(false);
            } else {
              setRecursiveData([]);
              setIsInitialLoading(false);
            }
          });
      } catch (e) {
        console.log(e);
      }
    }
  }, [token]);
  useEffect(() => {
    let callAPI;

    if (needRecursion.delta || needRecursion.signal) {
      callAPI = setIntervalAsync(async () => {
        const to = new Date();
        const from = new Date(to - 1000 * 60 * 60);
        try {
          const response = await axios.get(
            `${BASE_URL}/api/histogram?from_date=${from.toISOString()}&to_date=${to.toISOString()}`,
            {
              headers: {
                'x-access-token': token,
              },
            }
          );

          if (response.data.data.length > 0) {
            const responseData = response.data.data;
            setRecursiveData(responseData);
          } else setRecursiveData([]);
        } catch (e) {
          console.log(e);
        }
      }, 30000);
    } else {
      if (callAPI) {
        clearIntervalAsync(callAPI);
      }
    }
    return () => {
      if (callAPI) {
        clearIntervalAsync(callAPI);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRecursion]);

  const deltaBuckets = useSelector((state) => state.chartBuckets.deltaBuckets);
  const signalBuckets = useSelector(
    (state) => state.chartBuckets.signalBuckets
  );

  return (
    <Box sx={{}}>
      <Box sx={{ display: 'flex', height: '90vh' }}>
        <Sidebar />
        <Box sx={{ m: 5, flex: 5, position: 'relative' }}>
          <LiveMap />
        </Box>
      </Box>
      <Chart
        title='Delta Histogram'
        buckets={deltaBuckets}
        type='delta'
        color='rgba(42, 129, 226,.7)'
        needRecursion={needRecursion}
        setNeedRecursion={setNeedRecursion}
        recursiveData={recursiveData}
        isInitialLoading={isInitialLoading}
        setIsInitialLoading={setIsInitialLoading}
      />
      <Chart
        title='Signal Strength Histogram'
        buckets={signalBuckets}
        type='signal'
        color='#fff'
        needRecursion={needRecursion}
        setNeedRecursion={setNeedRecursion}
        recursiveData={recursiveData}
        isInitialLoading={isInitialLoading}
        setIsInitialLoading={setIsInitialLoading}
      />
    </Box>
  );
}
