import Routes from "./Router";

function App() {
  return (
    <div className="App" style={{ height: "100vh" }}>
      <Routes />
    </div>
  );
}

export default App;
