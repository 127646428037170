import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography } from "@mui/material";
import axios from "axios";

import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateTimePicker from "@mui/lab/DateTimePicker";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function BackupForm(props) {
  const { toggleOpen, open, setSnackbarProps, token } = props;

  const [error] = useState("");
  const initialLoad = useRef(true);
  const [currentTime, setCurrentTime] = useState(new Date());
  const [fromTime, setFromTime] = useState(
    new Date(new Date() - 24 * 60 * 60 * 1000)
  );
  const [toTime, setToTime] = useState(currentTime);
  const maxTime = useRef(currentTime);

  const handleCreateBackUp = async () => {
    const from = fromTime.toISOString();
    const to = toTime.toISOString();
    try {
      await axios.get(
        `${BASE_URL}/api/backup?from_date=${from}&to_date=${to}`,
        { headers: { "x-access-token": token} }
      );
      setSnackbarProps({
        open: true,
        severity: "success",
        msg: "Data dump successful!",
      });
    } catch (e) {
      setSnackbarProps({
        open: true,
        severity: "error",
        msg: "Failed to dump data",
      });
    }
  };

  useEffect(() => {
    if (initialLoad.current) {
      initialLoad.current = false;
    } else {
      setCurrentTime(new Date());
      const ft = new Date(fromTime);
      const time = new Date(ft.setHours(ft.getHours() + 24));
      if (time < currentTime) {
        maxTime.current = new Date(time);
      } else {
        maxTime.current = currentTime;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromTime]);

  return (
    <>
      <Dialog open={open} onClose={toggleOpen} sx={{ height: "100%" }}>
        <DialogTitle>Create Backup</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              my: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} size="small" />}
                label="From"
                value={fromTime}
                maxDateTime={currentTime}
                onChange={(newValue) => {
                  setFromTime(newValue);
                }}
              />

              <DateTimePicker
                renderInput={(props) => (
                  <TextField {...props} size="small" sx={{ mt: 2 }} />
                )}
                label="To"
                value={toTime}
                minDateTime={fromTime}
                maxDateTime={maxTime.current}
                onChange={setToTime}
              />
            </LocalizationProvider>
          </Box>

          <Typography>{error}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={toggleOpen}>Cancel</Button>
          <Button onClick={handleCreateBackUp}>Create</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
