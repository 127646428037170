import { createTheme } from "@mui/material";
const customTheme=createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#2a81e2',
    },
    secondary: {
      main: '#fff',
    },
  },
});

export default customTheme;