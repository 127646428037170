import React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
// import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useDispatch } from "react-redux";
import { addUserAsync } from "../../../Redux/usersSlice";

export default function AddUserModal(props) {
  const { handleClose, open, addFormData, setAddFormData, setSnackbarProps } =
    props;
  const handleAddFormData = (evt) => {
    const { name, value } = evt.target;
    setAddFormData({ ...addFormData, [name]: value });
  };

  const dispatch = useDispatch();

  const handleAddUser = async () => {
    await dispatch(addUserAsync({ ...addFormData }))
      .unwrap()
      .then(() => {
        setSnackbarProps({
          open: true,
          msg: "Added a new user",
          severity: "success",
        });
        handleClose();
      })
      .catch((error) => {
        setSnackbarProps({
          open: true,
          msg: "Failed to add user, Try again!",
          severity: "error",
        });
      });
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add User</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="username"
            name="username"
            label="Username"
            type="text"
            onChange={handleAddFormData}
            value={addFormData.dma_rank}
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="email"
            name="email"
            label="E-mail"
            type="email"
            value={addFormData.dma_code}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />
          <TextField
            margin="dense"
            id="password"
            name="password"
            label="Password"
            type="password"
            value={addFormData.dma_name}
            onChange={handleAddFormData}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleAddUser}>Add</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
