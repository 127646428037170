import React from "react";
import { SwipeableDrawer, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";

export default function Drawer({ open, toggleDrawer, children }) {
  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          width: { xs: "10rem", md: "18rem" },
        },
      }}
      anchor="left"
      open={open}
      onOpen={toggleDrawer(false)}
      onClose={toggleDrawer(true)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          width: { sm: "100%" },
        }}
      >
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      {children}
    </SwipeableDrawer>
  );
}
