import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Typography, Autocomplete } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { mapDeviceAsync } from "../../../Redux/devicesSlice";

export default function MapDeviceToRegion(props) {
  const { handleClose, open, mapFormData, setMapFormData, setSnackbarProps } =
    props;
  const regions = useSelector((state) => state.regions.regions);
  const options = ["None", ...regions.map((region) => region.dma_name)];
  const devices = useSelector((state) => state.devices.devices);
  const [inputValue, setInputValue] = useState("None");
  const dispatch = useDispatch();

  const handleMapFormData = (evt) => {
    const { name, value } = evt.target;
    setMapFormData({ ...mapFormData, [name]: value });
  };

  const handleRegionChange = async (event, newValue) => {
    if (!newValue) {
      setMapFormData({
        ...mapFormData,
        region: "",
        regionID: "",
      });
      return;
    }
    setMapFormData({
      ...mapFormData,
      region: newValue,
      regionID:
        newValue !== "None"
          ? regions.filter((region) => region.dma_name === newValue)[0].id
          : "",
      deviceID: devices.filter((device) => device.name === mapFormData.name)[0]
        .id,
    });
  };

  const handleMapDevice = async () => {
    await dispatch(mapDeviceAsync({ ...mapFormData }))
      .unwrap()
      .then(() => {
        setSnackbarProps({
          open: true,
          severity: "success",
          msg: "Mapped the region successfully",
        });
        handleClose();
      })
      .catch((err) => {
        setSnackbarProps({
          open: true,
          severity: "error",
          msg: "Failed to map device to region",
        });
      });
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} sx={{ height: "100%" }}>
        <DialogTitle>Map Device</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="device_name"
            name="name"
            label="Device Name"
            type="text"
            onChange={handleMapFormData}
            value={mapFormData.name}
            fullWidth
            variant="standard"
            disabled
          />

          <Typography>Map Device to Region</Typography>
          <Box sx={{ my: 1, maxWidth: "200px" }}>
            <Autocomplete
              value={mapFormData.region}
              onChange={handleRegionChange}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              id="controllable-states-demo"
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  name="region"
                  label="Region"
                />
              )}
              disableClearable
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleMapDevice}>Map</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
