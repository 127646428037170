import { Navigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthToken } from '../Helpers/deps';
import { logOut, setLogIn, logOutOnSessionExpire } from '../Redux/authSlice';

export default function RequireAuth({ children }) {
  const dispatch = useDispatch();
  const token = getAuthToken();

  if (token) {
    const now = new Date();
    const expiry = token.expiry;
    if (now.getTime() > expiry) {
      dispatch(logOut());
    } else {
      dispatch(setLogIn());
      const interval = token.expiry - now.getTime();
      setTimeout(() => {
        dispatch(logOutOnSessionExpire());
      }, interval);
    }
  } else {
    dispatch(logOut());
  }

  const authed = useSelector((state) => state.auth.isLoggedIn);

  return authed ? children : <Navigate to='/login' replace />;
}
