import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getDevicesAsync = createAsyncThunk(
  "devices/getDevicesAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;
    try {
      const devicesResponse = await axios.get(`${BASE_URL}/api/getAllDevice`, {
        headers: {
          "x-access-token": token,
        },
      });
      return devicesResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const addDeviceAsync = createAsyncThunk(
  "devices/addDeviceAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    try {
      const addDeviceResponse = await axios.post(
        `${BASE_URL}/api/device`,
        {
          name: payload.name,
          host: payload.host,
          driverName: payload.driverName,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      const deviceId = await addDeviceResponse.data.id;
      if (payload.regionID && addDeviceResponse.status === 200) {
        await axios.post(
          `${BASE_URL}/api/regionDevice`,
          {
            regionId: payload.regionID,
            deviceId: deviceId,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
      }
      return { ...payload, id: deviceId };
    } catch (err) {
      return rejectWithValue("Couldn't Add Region. Try Again!", err);
    }
  }
);

export const deleteDeviceAsync = createAsyncThunk(
  "devices/deleteDeviceAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    try {
      await axios.patch(
        `${BASE_URL}/api/device/${payload.id}`,
        {},
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't Delete Device. Try Again!", err);
    }
  }
);

export const updateDeviceAsync = createAsyncThunk(
  "devices/updateDeviceAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    try {
      const updateDeviceResponse = await axios.put(
        `${BASE_URL}/api/device/${payload.id}`,
        {
          name: payload.name,
          host: payload.host,
          driverName: payload.driverName,
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      if (payload.newRegion && updateDeviceResponse.status === 200) {
        await axios.put(
          `${BASE_URL}/api/updateRegionDevice`,
          {
            regionId: payload.newRegion === "None" ? "" : payload.regionID,
            deviceId: payload.id,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        return { ...payload, regions: [{ dma_name: payload.newRegion }] };
      }
      return { ...payload, regions: [{ dma_name: payload.region }] };
    } catch (err) {
      return rejectWithValue("Couldn't Update Device. Try Again!", err);
    }
  }
);

export const mapDeviceAsync = createAsyncThunk(
  "devices/mapDeviceAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      if (payload.region !== "None") {
        await axios.post(
          `${BASE_URL}/api/regionDevice`,
          {
            regionId: payload.regionID,
            deviceId: payload.deviceID,
          },
          {
            headers: {
              "x-access-token": token,
            },
          }
        );
        return payload;
      }
    } catch (err) {
      return rejectWithValue("Couldn't Map Region. Try Again!", err);
    }
  }
);

export const devicesSlice = createSlice({
  name: "devices",
  initialState: {
    devices: [],
  },
  reducers: {
    toggleUncorrected: (state, action) => {
      return { ...state, uncorrected: action.payload.uncorrected };
    },
  },
  extraReducers: {
    [getDevicesAsync.fulfilled]: (state, action) => {
      return { ...state, devices: action.payload };
    },
    [addDeviceAsync.fulfilled]: (state, action) => {
      if (action.payload.regionID)
        return {
          ...state,
          devices: [
            ...state.devices,
            {
              ...action.payload,
              regions: [{ dma_name: action.payload.region }],
            },
          ],
        };

      return {
        ...state,
        devices: [...state.devices, { ...action.payload, regions: [] }],
      };
    },
    [deleteDeviceAsync.fulfilled]: (state, action) => {
      const filteredDevices = state.devices.filter(
        (device) => device.id !== action.payload.id
      );
      return { ...state, devices: filteredDevices };
    },
    [updateDeviceAsync.fulfilled]: (state, action) => {
      const devices = [...state.devices];
      const deviceIndex = devices.findIndex(
        (device) => device.id === action.payload.id
      );

      if (deviceIndex > -1) {
        devices[deviceIndex] = action.payload;
      }

      return { ...state, devices: devices };
    },
    [mapDeviceAsync.fulfilled]: (state, action) => {
      const devices = [...state.devices];
      const deviceIndex = devices.findIndex(
        (device) => device.id === action.payload.deviceID
      );

      if (deviceIndex > -1) {
        devices[deviceIndex] = {
          ...devices[deviceIndex],
          regions: [{ dma_name: action.payload.region }],
        };
      }
      return { ...state, devices: devices };
    },
  },
});

export const { toggleUncorrected } = devicesSlice.actions;

export default devicesSlice.reducer;
