import React, { useState } from "react";
import { Container } from "@mui/material";
import CarTable from "./CarTable";
import CarColorForm from "./CarColorForm";
import ConfirmDialog from "./ConfirmDialog";
import { useSelector } from "react-redux";
import Snackbar from "../../../Components/Snackbar/Snackbar";

export default function Car() {
  const [carColorFormOpen, setCarColorFormOpen] = useState(false);

  const colors = useSelector((state) => state.carColor.deltaColors);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [confirmDeleteID, setConfirmDeleteID] = useState(null);
  const [currentColor, setCurrentColor] = useState(null);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    msg: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarProps({ ...snackbarProps, open: false });
  };

  const initialValues = {
    range_from: 0,
    range_to: 0,
    color: "#ffffff",
  };

  const isValidRange = (values, isEditing) => {
    const from = parseFloat(values.range_from); //range_from from form
    const to = parseFloat(
      values.range_to === null ? null : parseFloat(values.range_to)
    ); // range_to from form

    if (to !== null && to < from) return false;

    let flag = true;
    let filteredColors;

    if (isEditing) {
      filteredColors = colors.filter((color) => {
        return currentColor.range_from !== color.range_from;
      });
    } else {
      filteredColors = colors;
    }

    for (let color of filteredColors) {
      if (to !== null) {
        if (color.range_to !== null) {
          if (
            parseFloat(color.range_from) <= from &&
            from < parseFloat(color.range_to)
          ) {
            flag = false;
            break;
          }

          if (
            parseFloat(color.range_from) < to &&
            to <= parseFloat(color.range_to)
          ) {
            flag = false;
            break;
          }
        } else {
          if (
            !isEditing &&
            color.range_from >= from &&
            to >= color.range_from
          ) {
            flag = false;
            break;
          }
        }
      } else {
        if (
          from <= parseFloat(color.range_from) &&
          from <= parseFloat(color.range_to)
        ) {
          flag = false;
          break;
        }

        if (color.range_to === null) {
          flag = false;
          break;
        }
      }
    }

    return flag;
  };

  const [formValues, setFormValues] = useState(initialValues);

  const toggleFormOpen = (bin) => {
    setCarColorFormOpen(!carColorFormOpen);
    setCurrentColor(bin);
  };

  const handleConfirmOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Container>
      <CarTable
        toggleFormOpen={toggleFormOpen}
        setFormValues={setFormValues}
        initialValues={initialValues}
        confirmDeleteOpen={handleConfirmOpen}
        setConfirmDeleteID={setConfirmDeleteID}
        colors={colors}
      />
      <CarColorForm
        toggleOpen={toggleFormOpen}
        formValues={formValues}
        setFormValues={setFormValues}
        open={carColorFormOpen}
        isValidRange={isValidRange}
        setSnackbarProps={setSnackbarProps}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        id={confirmDeleteID}
        handleClose={handleConfirmClose}
        setSnackbarProps={setSnackbarProps}
      />
      <Snackbar
        snackbarOpen={snackbarProps.open}
        msg={snackbarProps.msg}
        severity={snackbarProps.severity}
        handleClose={handleSnackbarClose}
      />
    </Container>
  );
}
