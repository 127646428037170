import React, { useEffect, useState } from 'react';
import HistorySidebar from './HistorySidebar';
import HistoryMap from './HistoryMap';
import HistoryInfoBox from './HistoryInfoBox';
import { addHistoryDeviceInfo } from '../../Redux/dataSlice';
import { Box } from '@mui/material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useSelector } from 'react-redux';

const BASE_URL = process.env.REACT_APP_BASE_URL;

export default function History() {
  const [isLoading, setIsLoading] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const dispatch = useDispatch();

  useEffect(() => {
    const to = new Date();
    const from = new Date(to - 1000 * 60 * 15);

    const getData = async () => {
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/history?from_date=${from.toISOString()}&to_date=${to.toISOString()}`,
        {
          headers: {
            'x-access-token': token,
          },
        }
      );
      const responseData = response.data;
      setIsLoading(false);
      return responseData;
    };

    getData()
      .then((res) => {
        setHistoryData(res);
      })
      .catch((error) => console.log(error));
  }, [token]);

  useEffect(() => {
    const deviceInfo = [];
    if (historyData.data) {
      historyData.signal.forEach((device) => {
        historyData.euclidian_mean_display.forEach((ed) => {
          if (ed.device === device.device)
            deviceInfo.push({
              ...device,
              euclideanDistance: ed.euclidean_distance,
            });
        });
      });

      dispatch(addHistoryDeviceInfo({ data: deviceInfo }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyData]);

  const getHistoryData = async (from, to) => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        `${BASE_URL}/api/history?from_date=${from.toISOString()}&to_date=${to.toISOString()}`,
        {
          headers: {
            'x-access-token': token,
          },
        }
      );
      const responseData = response.data;
      setHistoryData(responseData);
      setIsLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        height: '90vh',
        position: 'relative',
      }}
    >
      <HistorySidebar
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        getHistoryData={getHistoryData}
      />
      <Box sx={{ m: 5, flex: 5, position: 'relative' }}>
        <HistoryMap
          isLoading={isLoading}
          setIsLoading={setIsLoading}
          historyData={historyData.data}
        />
        <HistoryInfoBox />
      </Box>
    </Box>
  );
}
