import MapIcon from "@mui/icons-material/Map";
import HistoryIcon from "@mui/icons-material/History";
import PeopleIcon from "@mui/icons-material/People";
import RegionIcon from "@mui/icons-material/LocationOn";
import DashboardIcon from "@mui/icons-material/Dashboard";
import DeviceIcon from "@mui/icons-material/DeviceHub";
import BackupIcon from "@mui/icons-material/Backup";
import CarIcon from "@mui/icons-material/LocalCarWash";
import ChartIcon from "@mui/icons-material/BarChart";
const userDrawerContent = [
  {
    path: "/user/map",
    icon: <MapIcon />,
    text: "Map",
  },
  {
    path: "/user/history",
    icon: <HistoryIcon />,
    text: "History",
  },
];

const adminDrawerContent = [
  {
    path: "/admin/dashboard",
    icon: <DashboardIcon />,
    text: "Dashboard",
  },
  {
    path: "/admin/users",
    icon: <PeopleIcon />,
    text: "Manage Users",
  },
  {
    path: "/admin/regions",
    icon: <RegionIcon />,
    text: "Manage Regions",
  },
  {
    path: "/admin/devices",
    icon: <DeviceIcon />,
    text: "Manage Devices",
  },
  {
    path: "/admin/backup",
    icon: <BackupIcon />,
    text: "Back Up",
  },
  {
    path: "/admin/car",
    icon: <CarIcon />,
    text: "Customise Car Visualisation",
  },
  {
    path: "/admin/chart",
    icon: <ChartIcon />,
    text: "Configure Histogram",
  },
];

export { userDrawerContent, adminDrawerContent };
