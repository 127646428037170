import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getRegionsAsync = createAsyncThunk(
  "regions/getRegionsAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const regionsResponse = await axios.get(`${BASE_URL}/api/getAllRegion`, {
        headers: {
          "x-access-token": token,
        },
      });
      return regionsResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const addRegionAsync = createAsyncThunk(
  "regions/addRegionAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;
    try {
      const regionsResponse = await axios.post(
        `${BASE_URL}/api/region`,
        {
          dmaRank: payload.dma_rank,
          dmaCode: payload.dma_code,
          dmaName: payload.dma_name,
          dmaCentroid:
            "POINT (" + payload.latitude + " " + payload.longitude + ")",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      return regionsResponse.data;
    } catch (err) {
      return rejectWithValue("Couldn't Add Region. Try Again!", err);
    }
  }
);

export const deleteRegionAsync = createAsyncThunk(
  "regions/deleteRegionAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.patch(
        `${BASE_URL}/api/region/${payload.id}`,
        {},
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      return payload;
    } catch (err) {
      return rejectWithValue("Couldn't Delete Region. Try Again!", err);
    }
  }
);

export const updateRegionAsync = createAsyncThunk(
  "regions/updateRegionAsync",
  async (payload, { rejectWithValue, getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      await axios.put(
        `${BASE_URL}/api/region/${payload.id}`,
        {
          dmaRank: payload.dma_rank,
          dmaCode: payload.dma_code,
          dmaName: payload.dma_name,
          dmaCentroid:
            "POINT (" + payload.latitude + " " + payload.longitude + ")",
        },
        {
          headers: {
            "x-access-token": token,
          },
        }
      );

      return {
        ...payload,
        dma_centroid:
          "POINT (" + payload.latitude + " " + payload.longitude + ")",
      };
    } catch (err) {
      return rejectWithValue("Couldn't Update Region. Try Again!", err);
    }
  }
);

export const regionsSlice = createSlice({
  name: "regions",
  initialState: {
    regions: [],
  },
  reducers: {
    toggleUncorrected: (state, action) => {
      return { ...state, uncorrected: action.payload.uncorrected };
    },
  },
  extraReducers: {
    [getRegionsAsync.fulfilled]: (state, action) => {
      return { ...state, regions: action.payload };
    },
    [addRegionAsync.fulfilled]: (state, action) => {
      return { ...state, regions: [...state.regions, action.payload] };
    },
    [deleteRegionAsync.fulfilled]: (state, action) => {
      const filteredRegions = state.regions.filter(
        (region) => region.id !== action.payload.id
      );
      return { ...state, regions: filteredRegions };
    },
    [updateRegionAsync.fulfilled]: (state, action) => {
      const regions = [...state.regions];
      const regionIndex = regions.findIndex(
        (region) => region.id === action.payload.id
      );

      if (regionIndex > -1) {
        regions[regionIndex] = action.payload;
      }
      return { ...state, regions: regions };
    },
  },
});

export const { toggleUncorrected } = regionsSlice.actions;

export default regionsSlice.reducer;
