import Modal from "@mui/material/Modal";
import { Box, IconButton, Typography } from "@mui/material";
import LockResetIcon from "@mui/icons-material/LockReset";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: "90%", md: "30rem" },
  bgcolor: "background.paper",
  outline: "none",
  borderRadius: 2,
  boxShadow: 24,
  p: 2,
};

export default function ResetModal({ modalOpen, toggleOpen }) {
  return (
    <div>
      <Modal
        open={modalOpen}
        onClose={toggleOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <IconButton onClick={toggleOpen} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <LockResetIcon
              sx={{
                fontSize: "h3.fontSize",
                color: "warning.light",
                fontWeight: "bold",
                marginRight: 1,
              }}
            />
            <Typography variant="h5" component="h2">
              Reset Password
            </Typography>
          </Box>

          <Box sx={{ py: 1, my: 1 }}>
            <Typography variant="body1" align="center">
              Please contact the administrator to reset password
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
