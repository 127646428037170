import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import MapContainer from "./StyledComponents/MapContainer";
import StyledSidebar from "./StyledComponents/StyledSidebar";
import { Box, CircularProgress } from "@mui/material";
import car from "../../Assets/Images/car.png";
import dot from "../../Assets/Images/dot.png";
import triangle from "../../Assets/Images/triangle.png";
import { convertJSONToString, showPopUp } from "./Helpers";
import mapboxgl from "mapbox-gl";
import "./popUpStyles.css";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_KEY;

function HistoryMap(props) {
  const { isLoading, historyData } = props;

  // Creating reference
  const initialMount = useRef();
  initialMount.current = true;

  const mapContainerRef = useRef(null);
  const map = useRef(null);
  const [lng, setLng] = useState(5);
  const [lat, setLat] = useState(34);
  const [zoom, setZoom] = useState(1.5);

  const devices = useSelector((state) => state.data.historyDevices).map(
    (device) => device.name
  );

  const allD = [];

  devices.forEach((device) => {
    allD.push(device);
    allD.push(device + " U");
  });

  const allDevices = allD;

  const selectedHistoryDevices = useSelector(
    (state) => state.data.selectedHistoryDevices
  );

  const historyUncorrectedState = useSelector(
    (state) => state.data.historyUncorrected
  );

  useEffect(() => {
    map.current = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11?optimize=true",
      zoom: 0,
    });

    map.current.addControl(new mapboxgl.NavigationControl(), "top-right");

    map.current.on("move", () => {
      setLng(map.current.getCenter().lng.toFixed(4));
      setLat(map.current.getCenter().lat.toFixed(4));
      setZoom(map.current.getZoom().toFixed(2));
    });

    map.current.on("load", async () => {
      const geojson = await getLocation([]);
      const [lat, lon] = [45.512230, -122.658722];
      map.current.flyTo({
        center: [lon, lat],
        speed: 0.5,
        zoom: 7,
      });

      // Load custom image
      map.current.loadImage(car, (error, image) => {
        if (error) throw error;
        map.current.addImage("car-icon", image, { sdf: true });
      });

      map.current.loadImage(dot, (error, image) => {
        if (error) throw error;
        map.current.addImage("dot-icon", image, { sdf: true });
      });
      map.current.loadImage(triangle, (error, image) => {
        if (error) throw error;
        map.current.addImage("triangle-icon", image, { sdf: true });
      });

      // Add the atsc-device location as a source.
      map.current.addSource("atsc-device", {
        type: "geojson",
        data: geojson,
        cluster: true,
        clusterMaxZoom: 13,
        clusterRadius: 20,
      });

      map.current.addLayer({
        id: "clusters",
        type: "circle",
        source: "atsc-device",
        filter: ["has", "point_count"],
        paint: {
          // Use step expressions (https://docs.mapbox.com/mapbox-gl-js/style-spec/#expressions-step)
          // with three steps to implement three types of circles:
          //   * Blue, 20px circles when point count is less than 100
          //   * Yellow, 30px circles when point count is between 100 and 750
          //   * Pink, 40px circles when point count is greater than or equal to 750
          "circle-color": [
            "step",
            ["get", "point_count"],
            "#51bbd6",
            100,
            "#f1f075",
            750,
            "#f28cb1",
          ],
          "circle-radius": [
            "step",
            ["get", "point_count"],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      map.current.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "atsc-device",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
        },
      });

      map.current.addLayer({
        id: "atsc-device",
        type: "symbol",
        source: "atsc-device",
        layout: {
          "icon-image": ["get", "icon"],
          "icon-size": ["get", "iconsize"],
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
          "text-field": ["get", "title"],
          "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
          "text-offset": [0, 1],
          "text-anchor": "top",
        },
        filter: ["!", ["has", "point_count"]],
        paint: {
          "icon-color": ["get", "CAR_TYPE"],
        },
      });
      map.current.on("click", "clusters", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: ["clusters"],
        });
        const clusterId = features[0].properties.cluster_id;
        map.current
          .getSource("atsc-device")
          .getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return;

            map.current.easeTo({
              center: features[0].geometry.coordinates,
              zoom: zoom,
            });
          });
      });
    });

    return () => {
      map.current.remove();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (map.current.getSource("atsc-device")) {
      let data = [];
      if (!selectedHistoryDevices.includes("All")) {
        const devicesToDisplay = [];
        selectedHistoryDevices.forEach((device) => {
          devicesToDisplay.push(device);
          devicesToDisplay.push(device + " U");
        });

        if (historyUncorrectedState) {
          data = historyData.filter((item) =>
            devicesToDisplay.includes(item.index)
          );
        } else {
          data = historyData.filter((item) =>
            selectedHistoryDevices.includes(item.index)
          );
        }
      } else {
        if (historyUncorrectedState) {
          data = historyData.filter((item) => allDevices.includes(item.index));
        } else {
          data = historyData.filter((item) => devices.includes(item.index));
        }
      }

      getLocation(data).then((geodata) => {
        map.current.getSource("atsc-device").setData(geodata);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyData, historyUncorrectedState, selectedHistoryDevices]);

  async function getLocation(historyData) {
    try {
      const geoData = {};

      if (historyData.length === 0) {
        return { type: "FeatureCollection", features: [] };
      }
      historyData.forEach((data) => {
        if (data.index in geoData) {
          geoData[data.index] = [...geoData[data.index], data];
        } else {
          geoData[data.index] = [data];
        }
      });

      const feats = [];

      Object.values(geoData).forEach((collection) => {
        collection.forEach((feature) => {
          let lastIndex = collection.length - 1;
          let isLast = collection.indexOf(feature) === lastIndex;

          feats.push({
            type: "Feature",
            properties: {
              title: isLast
                ? feature.uncorrected_data
                  ? ""
                  : feature.index + " " + feature.driverName || "Null"
                : "",
              index: feature.index,
              icon: isLast
                ? feature.uncorrected_data
                  ? "triangle-icon"
                  : "car-icon"
                : feature.uncorrected_data
                ? "triangle-icon"
                : "dot-icon",
              iconsize: isLast ? 0.1 : 0.2,
              CAR_TYPE: feature.colour,
              description: convertJSONToString(feature.device_stats),
            },
            geometry: {
              type: "Point",
              coordinates: [feature.lon, feature.lat],
            },
          });
        });
      });

      showPopUp(map.current, "atsc-device");

      return {
        type: "FeatureCollection",
        features: feats,
      };
    } catch (err) {
      console.log("error", err);
    }
  }

  return (
    <>
      <>
        <StyledSidebar>
          <div>
            Longitude: {lng} | Latitude: {lat} | Zoom: {zoom}
          </div>
        </StyledSidebar>
        <MapContainer ref={mapContainerRef} />
        {isLoading && (
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%,-50%)",
            }}
          >
            {" "}
            <CircularProgress sx={{ color: "#000" }} />
          </Box>
        )}
      </>
    </>
  );
}

export default HistoryMap;
