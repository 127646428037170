import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./authSlice";
import dataSlice from "./dataSlice";
import devicesSlice from "./devicesSlice";
import regionsSlice from "./regionsSlice";
import usersSlice from "./usersSlice";
import carColorSlice from "./carColorSlice";
import chartSlice from "./chartSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    data: dataSlice,
    regions: regionsSlice,
    devices: devicesSlice,
    users: usersSlice,
    carColor: carColorSlice,
    chartBuckets: chartSlice,
  },
});
