import React from "react";
import CenteredForm from "../../Components/CenteredForm";

import { useState } from "react";

import {
  Typography,
  Button,
  Box,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import BadgeIcon from "@mui/icons-material/Badge";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { signUpUserAsync } from "../../Redux/usersSlice";

export default function Register() {
  const [showPassword, setShowPassword] = useState(false);
  const initialValues = { username: "", email: "", password: "" };
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [requestError, setRequestError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(() => ({ ...formValues, [name]: value }));
  };
  const togglePassword = () => setShowPassword(!showPassword);
  const validate = (values) => {
    const errors = {};
    //eslint-disable-next-line
    const email_regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    //eslint-disable-next-line
    const password_regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    if (!values.username) {
      errors.username = "Name is required!";
    }
    if (!values.email) {
      errors.email = "E-mail is required!";
    } else if (!email_regex.test(values.email)) {
      errors.email = "This is not a valid email";
    }
    if (!values.password) {
      errors.password = "Password is required!";
    } else if (!password_regex.test(values.password)) {
      errors.password =
        "Password must be min 8 characters at least 1 uppercase letter, 1 number and 1 special character";
    }
    return errors;
  };

  const handleSubmit = async (e) => {
    setFormErrors(validate(formValues));
    if (Object.keys(formErrors).length === 0) {
      await dispatch(signUpUserAsync(formValues))
        .unwrap()
        .then(() => {
          navigate("/login");

        })
        .catch((error) => {
          setRequestError(error);
        });
    }
  };

  return (
    <CenteredForm>
      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
        }}
      >
        Create your account
      </Typography>

      <Typography
        variant="subtitle1"
        sx={{
          textAlign: "center",
          margin: 2,
        }}
      >
        Already have an account?{" "}
        <Link to="/login">
          <span style={{ color: "#2a81e2" }}>Login</span>
        </Link>
      </Typography>

      <TextField

        name="username"

        value={formValues.name}
        onChange={handleChange}
        label="Name"
        variant="outlined"
        placeholder="Please enter your username"
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{
                padding: 1,
              }}
              position="end"
            >
              <BadgeIcon
                sx={{
                  color: "rgba(0,0,0,0.8)",
                }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          height: "2rem",
        }}
      >
        <Typography variant="caption" component="h2" color="error.main">
          {formErrors.name}
        </Typography>
      </Box>

      <TextField
        name="email"
        value={formValues.email}
        onChange={handleChange}
        label="E-mail"
        variant="outlined"
        placeholder="Please enter your email"
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{
                padding: 1,
              }}
              position="end"
            >
              <EmailIcon
                sx={{
                  color: "rgba(0,0,0,0.8)",
                }}
              />
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          height: "2rem",
        }}
      >
        <Typography variant="caption" component="h2" color="error.main">
          {formErrors.email}
        </Typography>
      </Box>

      <TextField
        name="password"
        value={formValues.password}
        onChange={handleChange}
        label="Password"
        type={showPassword ? "text" : "password"}
        variant="outlined"
        placeholder="Please enter password"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={togglePassword}>
                {showPassword ? (
                  <VisibilityOffIcon
                    sx={{
                      color: "error.main",
                    }}
                  />
                ) : (
                  <VisibilityIcon
                    sx={{
                      color: "success.main",
                    }}
                  />
                )}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Box
        sx={{
          height: "2rem",
        }}
      >
        <Typography variant="caption" component="h2" color="error.main">
          {formErrors.password}
        </Typography>
      </Box>

      <Button
        variant="contained"
        onClick={handleSubmit}
        color="primary"
        sx={{
          padding: 1,
        }}
      >
        Register
      </Button>
      <Box
        sx={{
          height: "1rem",
        }}
      >
        <Typography
          variant="subtitle1"
          component="h2"
          color="error.main"
          align="center"
        >
          {requestError}
        </Typography>
      </Box>
    </CenteredForm>
  );
}
