import styled from "styled-components";

const StyledSidebar = styled.div`
  display: inline-block;
  position: relative;
  left: 0;
  margin: 12px;
  background-color: #404040;
  color: #ffffff;
  z-index: 1 !important;
  padding: 6px;
  font-weight: bold;
  @media (max-width: 600px) {
    max-width: 170px;
    font-size: 0.7rem;
`;

export default StyledSidebar;
