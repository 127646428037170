import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const axios = require("axios");

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const getAllDevicesAsync = createAsyncThunk(
  "data/getAllDevicesAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const devicesResponse = await axios.get(`${BASE_URL}/api/getAllDevice`, {
        headers: {
          "x-access-token": token,
        },
      });

      return devicesResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const getAllRegionsAsync = createAsyncThunk(
  "data/getAllRegionsAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const regionsResponse = await axios.get(`${BASE_URL}/api/getAllRegion`, {
        headers: {
          "x-access-token": token,
        },
      });

      return regionsResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const getRegionDevicesAsync = createAsyncThunk(
  "data/getRegionDevicesAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const regionsResponse = await axios.get(
        `${BASE_URL}/api/regionDevice?id=${payload.id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return regionsResponse.data;
    } catch (err) {
      throw err;
    }
  }
);

export const getHistoryRegionDevicesAsync = createAsyncThunk(
  "data/getHistoryRegionDevicesAsync",
  async (payload, { getState }) => {
    const state = getState();
    const token = state.auth.token;

    try {
      const historyRegionsResponse = await axios.get(
        `${BASE_URL}/api/regionDevice?id=${payload.id}`,
        {
          headers: {
            "x-access-token": token,
          },
        }
      );
      return historyRegionsResponse.data;
    } catch (err) {
      throw err;
    }
  }
);
export const dataSlice = createSlice({
  name: "data",
  initialState: {
    uncorrected: false,
    onlyLastCoordinate: true,
    devices: [],
    regions: [],
    selectedDevices: [],
    selectedRegion: "",
    historyUncorrected: true,
    historyDevices: [],
    historyRegions: [],
    selectedHistoryDevices: [],
    selectedHistoryRegion: "",
    deviceInfo: [],
    historyDeviceInfo: [],
  },
  reducers: {
    toggleUncorrected: (state, action) => {
      return { ...state, uncorrected: action.payload.uncorrected };
    },
    toggleHistoryUncorrected: (state, action) => {
      return { ...state, historyUncorrected: action.payload.uncorrected };
    },
    toggleOnlyLastCoordinate: (state, action) => {
      return {
        ...state,
        onlyLastCoordinate: action.payload.onlyLastCoordinate,
      };
    },
    selectDevices: (state, action) => {
      return { ...state, selectedDevices: action.payload.selectedDevices };
    },
    selectHistoryDevices: (state, action) => {
      return {
        ...state,
        selectedHistoryDevices: action.payload.selectedHistoryDevices,
      };
    },
    selectRegion: (state, action) => {
      return { ...state, selectedRegion: action.payload.region };
    },
    selectHistoryRegion: (state, action) => {
      return { ...state, selectedHistoryRegion: action.payload.region };
    },
    addDeviceInfo: (state, action) => {
      return { ...state, deviceInfo: action.payload.data };
    },
    addHistoryDeviceInfo: (state, action) => {
      return { ...state, historyDeviceInfo: action.payload.data };
    },
  },
  extraReducers: {
    [getAllDevicesAsync.fulfilled]: (state, action) => {
      return {
        ...state,
        devices: action.payload,
        historyDevices: action.payload,
      };
    },
    [getAllRegionsAsync.fulfilled]: (state, action) => {
      return { ...state, regions: action.payload };
    },
    [getRegionDevicesAsync.fulfilled]: (state, action) => {
      return { ...state, devices: action.payload };
    },
    [getHistoryRegionDevicesAsync.fulfilled]: (state, action) => {
      return { ...state, historyDevices: action.payload };
    },
  },
});

export const {
  toggleUncorrected,
  selectDevices,
  selectRegion,
  toggleHistoryUncorrected,
  selectHistoryDevices,
  selectHistoryRegion,
  addDeviceInfo,
  addHistoryDeviceInfo,
  addInitialHistoryData,
  toggleOnlyLastCoordinate,
} = dataSlice.actions;

export default dataSlice.reducer;
