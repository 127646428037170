import React, { useState } from "react";
import AddUserModal from "./AddUserModal";
import ChangePasswordModal from "./ChangePasswordModal";
import UsersTable from "./UsersTable";
import ConfirmDialog from "./ConfirmDialog";
import Snackbar from "../../../Components/Snackbar/Snackbar";

export default function Users() {
  const [addUserFormOpen, setAddUserFormOpen] = useState(false);
  const [changePasswordFormOpen, setChangePasswordFormOpen] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [snackbarProps, setSnackbarProps] = useState({
    open: false,
    msg: "",
    severity: "error",
  });

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackbarProps({ ...snackbarProps, open: false });
  };

  const [changePasswordFormData, setChangePasswordFormData] = useState({
    id: "",
    password: "",
  });

  const [addFormData, setAddFormData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const [confirmToggleData, setConfirmToggleData] = useState(null);

  const handleConfirmOpen = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmDialog(false);
  };

  const handleAddClickOpen = () => {
    setAddUserFormOpen(true);
  };

  const handleAddClose = () => {
    setAddUserFormOpen(false);
  };

  const handleChangePasswordClickOpen = () => {
    setChangePasswordFormOpen(true);
  };

  const handleChangePasswordClose = () => {
    setChangePasswordFormOpen(false);
  };

  return (
    <>
      <AddUserModal
        nModal
        handleClickOpen={handleAddClickOpen}
        handleClose={handleAddClose}
        open={addUserFormOpen}
        addFormData={addFormData}
        setAddFormData={setAddFormData}
        setSnackbarProps={setSnackbarProps}
      />

      <ChangePasswordModal
        handleClickOpen={handleChangePasswordClickOpen}
        handleClose={handleChangePasswordClose}
        open={changePasswordFormOpen}
        editFormData={changePasswordFormData}
        setEditFormData={setChangePasswordFormData}
        setSnackbarProps={setSnackbarProps}
      />
      <UsersTable
        addUserFormOpen={handleAddClickOpen}
        changePasswordFormOpen={handleChangePasswordClickOpen}
        setEditFormData={setChangePasswordFormData}
        confirmToggleOpen={handleConfirmOpen}
        setConfirmToggleData={setConfirmToggleData}
      />
      <ConfirmDialog
        open={openConfirmDialog}
        data={confirmToggleData}
        handleClose={handleConfirmClose}
        setSnackbarProps={setSnackbarProps}
      />

      <Snackbar
        snackbarOpen={snackbarProps.open}
        msg={snackbarProps.msg}
        severity={snackbarProps.severity}
        handleClose={handleSnackbarClose}
      />
    </>
  );
}
